import { SearchPicker, Dropdown, Spinner, Tile } from '@optimizely/axiom';
import React, { useState } from 'react';

import config from 'atomic-config';

import ui from 'core/ui';

const iceApiBaseUrl = `${config.get('env.ICE_BASE_URL')}/api`;

const searchHypothesesByProjectId = projectId => async () => {
  const iceUrl = `${iceApiBaseUrl}/experimentation-project/${projectId}/hypotheses`;
  try {
    const response = await fetch(iceUrl, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      ui.showNotification({
        message:
          'There was an error fetching hypotheses. However, this should not prevent you from creating the experiment.',
        type: 'error',
      });
      return [];
    }
    return response.json();
  } catch (error) {
    ui.showNotification({
      message:
        'There was an error fetching hypotheses. However, this should not prevent you from creating the experiment.',
      type: 'error',
    });
    return [];
  }
};

const HypothesisLinkSearchPicker = ({ projectId }) => {
  const [hypothesis, setHypothesis] = useState(null);
  return (
    <>
      <h6>Link Hypothesis</h6>
      {hypothesis && (
        <Tile
          name={hypothesis.name}
          description={`${hypothesis.campaign_name} • HPT-${hypothesis.hpt_reference} • ${hypothesis.status}`}
          onDismiss={() => {
            setHypothesis(null);
          }}
          className="width--300"
        />
      )}
      {!hypothesis && (
        <SearchPicker
          searchFunction={searchHypothesesByProjectId(projectId)}
          supportedTypes={['hypothesis']}>
          {({ availableEntities, isLoading, renderInput, searchQuery }) => (
            <Dropdown width={400} renderActivator={renderInput}>
              <Dropdown.Contents isLoading={isLoading}>
                {!isLoading &&
                  availableEntities &&
                  availableEntities.map(hypothesis => (
                    <Dropdown.ListItem key={hypothesis.id}>
                      <Dropdown.BlockLink
                        onClick={() => {
                          setHypothesis(hypothesis);
                        }}>
                        <Dropdown.BlockLinkText text={hypothesis.name} />
                        <Dropdown.BlockLinkSecondaryText
                          secondaryText={`${hypothesis.campaign_name} • HPT-${hypothesis.hpt_reference} • ${hypothesis.status}`}
                        />
                      </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                  ))}
                {!isLoading && availableEntities.length === 0 && (
                  <Dropdown.ListItem>
                    <span className="micro muted soft--sides">
                      {searchQuery
                        ? `No hypothesis found containing "${searchQuery}"`
                        : 'No hypothesis found'}
                    </span>
                  </Dropdown.ListItem>
                )}
                {isLoading && <Spinner size="small" />}
              </Dropdown.Contents>
            </Dropdown>
          )}
        </SearchPicker>
      )}
    </>
  );
};

export default HypothesisLinkSearchPicker;
